.cb-tabs-menu {
  display: flex;
  height: 72px;
}
.cb-tabs-menu ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  height: 100%;
}
.cb-tabs-menu ul li {
  list-style: none;
}
.cb-tabs-menu ul li a {
  display: flex;
  align-items: flex-end;
  padding: 0 24px 12px;
  height: 100%;
  text-decoration: none !important;
}
.cb-tabs-menu ul li a.cb-selected {
  box-shadow: inset 0px -3px 0px -1px #1e1e1e;
}
.cb-tabs-menu ul li a:hover, .cb-tabs-menu ul li a.cb-hover {
  box-shadow: inset 0px -3px 0px -1px #1e1e1e;
}
.cb-tabs-menu ul li a.cb-disabled, .cb-tabs-menu ul li a:disabled {
  cursor: not-allowed;
  opacity: 0.5;
  text-decoration: none !important;
  background-color: #f0f0f0;
  border-color: #f0f0f0;
  color: #b2b2b2;
}
.cb-tabs-menu.cb-tabs-menu-icon {
  height: auto;
}
.cb-tabs-menu.cb-tabs-menu-icon ul li a {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}
.cb-tabs-menu.cb-tabs-menu-icon ul li a span[class^=cb-glyph],
.cb-tabs-menu.cb-tabs-menu-icon ul li a span[class^=cb-icon] {
  margin-bottom: 8px;
  margin-top: 10px;
  font-size: 26px;
}
.cb-tabs-menu + .cb-tabs-container {
  margin-top: 48px;
}

.cb-tabs-container .cb-tabs-content {
  display: none;
}
.cb-tabs-container .cb-tabs-content.cb-selected {
  display: block;
}

[data-cb-input-m=keyboard] .cb-tabs-menu a.cb-menu-link:focus, [data-cb-input-m=keyboard] .cb-tabs-menu a.cb-menu-link.cb-focus {
  background-color: #f0f0f0;
  outline: auto !important;
  outline-color: #7ab6fd !important;
}